import { Box, Container, Text } from "@chakra-ui/react";

export default function Header() {
	return (
		<Box display="flex">
			<Container py={4} justify="center" align="center">
				<Text fontSize="4xl">SpaceX Launches</Text>
			</Container>
		</Box>
	);
}
