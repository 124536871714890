import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import spacexData from "../api/spacex";

export const fetchLaunches = createAsyncThunk("launches/all", async () => {
	const response = await spacexData.get(`/launches`);
	return response.data;
});

const launchSlice = createSlice({
	name: "launches",
	initialState: {
		all: [],
		displayLaunches: [],
		filterData: [],
		resetFilters: false,
	},

	reducers: {
		searchByRocket: (state, action) => {
			const term = action.payload;
			const searchResult = state.all.filter((launch) =>
				launch.rocket.rocket_name.toLowerCase().includes(term.toLowerCase())
			);
			state.displayLaunches = searchResult;
			state.filterData = searchResult;
		},

		resetFilters: (state, action) => {
			state.resetFilters = action.payload;
		},

		filter: (state, action) => {
			let result = state.filterData;

			result =
				action.payload.upcoming !== null ? result.filter((launch) => launch.upcoming) : result;

			result =
				action.payload.status !== null
					? result.filter((launch) => launch.launch_success === action.payload.status)
					: result;

			result =
				action.payload.launchDate !== null
					? result.filter(
							(launch) => new Date(launch.launch_date_local) > new Date(action.payload.launchDate)
					  )
					: result;

			state.displayLaunches = result;
		},
	},

	extraReducers: {
		[fetchLaunches.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchLaunches.fulfilled]: (state, action) => {
			state.all = action.payload;
			state.displayLaunches = action.payload;
			state.filterData = action.payload;
			state.loading = false;
		},
	},
});

export const { searchByRocket, filter, resetFilters } = launchSlice.actions;

export default launchSlice.reducer;
