import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../components/filter/Filters";
import LaunchList from "../components/LaunchList";
import SearchByRocket from "../components/search/SearchByRocket";
import LaunchListSkeleton from "../components/skeletons/LaunchListSkeleton";
import { fetchLaunches } from "../redux/launchSlice";

export default function Launches() {
	const dispatch = useDispatch();
	const launches = useSelector((state) => state.launches.displayLaunches);
	const loading = useSelector((state) => state.launches.loading);

	useEffect(() => {
		dispatch(fetchLaunches());
	}, [dispatch]);

	return (
		<>
			<Flex dir="column" justify="center" wrap="wrap" px={{ base: "10", lg: "20" }}>
				<SearchByRocket />
				<Filters />
			</Flex>
			{!loading ? <LaunchList launches={launches} /> : <LaunchListSkeleton />}
		</>
	);
}
