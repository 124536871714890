/* eslint-disable react/jsx-props-no-spreading */
import { CalendarIcon } from "@chakra-ui/icons";
import { HStack, Text, useRadioGroup } from "@chakra-ui/react";
import RadioCard from "../RadioCard";

export default function FilterByLaunchDate({ setFilters, filters }) {
	const week = new Date();
	week.setDate(week.getDate() - 7);

	const month = new Date();
	month.setDate(month.getDate() - 30);

	const year = new Date();
	year.setDate(year.getDate() - 365);

	const options = ["Last Week", "Last Month", "Last Year", "Any"];
	const getValue = (string) => {
		if (string === "Last Week") return week.toString();
		if (string === "Last Month") return month.toString();
		if (string === "Last Year") return year.toString();
		return null;
	};

	const { getRadioProps } = useRadioGroup({
		name: "LaunchDates",
		onChange: (e) => setFilters({ ...filters, launchDate: getValue(e) }),
	});

	return (
		<HStack wrap="wrap" spacing="2">
			<CalendarIcon />
			<Text fontWeight="bold">Launch Date: </Text>
			{options.map((value) => {
				const radio = getRadioProps({ value });
				return (
					<RadioCard key={value} {...radio}>
						{value}
					</RadioCard>
				);
			})}
		</HStack>
	);
}
