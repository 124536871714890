import { Grid } from "@chakra-ui/react";
import React from "react";
import LaunchCard from "./LaunchCard";

export default function LaunchList({ launches }) {
	return (
		<Grid
			templateColumns={{ base: "repeat( 1, 1fr)", lg: "repeat( 2, 1fr)" }}
			gap={6}
			px={{ base: "10", lg: "20" }}
			justifyContent="center"
		>
			{launches.map((launch) => (
				<LaunchCard launch={launch} key={launch.flight_number + launch.mission_name} />
			))}
		</Grid>
	);
}
