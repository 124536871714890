import { HStack, Text } from "@chakra-ui/react";

export default function LaunchCardText({ label, data, icon, noOfLines }) {
	return (
		<HStack>
			{icon}
			<Text noOfLines={noOfLines}>
				<b>{label}: </b>
				{data}
			</Text>
		</HStack>
	);
}
