/* eslint-disable react/no-children-prop */
import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { resetFilters, searchByRocket } from "../../redux/launchSlice";

export default function SearchByRocket() {
	const dispatch = useDispatch();

	return (
		<InputGroup w="lg" mr={{ base: 10, lg: "0" }}>
			<InputLeftElement pointerEvents="none" children={<SearchIcon color="blue.200" />} />
			<Input
				type="search"
				onFocus={() => dispatch(resetFilters(true))}
				onChange={(e) => dispatch(searchByRocket(e.target.value))}
				placeholder="Search by Rocket Name"
			/>
		</InputGroup>
	);
}
