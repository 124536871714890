import { Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Launches from "./pages/Launches";

function App() {
	return (
		<div className="App">
			<Flex direction="column" height="100vh">
				<Header />
				<Launches />
				<Footer />
			</Flex>
		</div>
	);
}

export default App;
