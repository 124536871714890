import { Grid, Skeleton } from "@chakra-ui/react";
import React from "react";

export default function LaunchListSkeleton() {
	return (
		<Grid
			templateColumns={{ base: "repeat( 1, 1fr)", lg: "repeat( 2, 1fr)" }}
			gap={6}
			px={{ base: "10", lg: "20" }}
			justifyContent="center"
		>
			{[...Array(20)].map(() => (
				<Skeleton height="300px" bg="blue.50" />
			))}
		</Grid>
	);
}
