/* eslint-disable react/jsx-props-no-spreading */
import { HStack, Text, useRadioGroup } from "@chakra-ui/react";
import { GrLaunch } from "react-icons/gr";
import RadioCard from "../RadioCard";

export default function FilterByLaunchStatus({ setFilters, filters }) {
	const options = ["Success", "Failure", "Any"];
	const getValue = (string) => {
		if (string === "Success") return true;
		if (string === "Failure") return false;
		return null;
	};

	const { getRadioProps } = useRadioGroup({
		name: "LaunchStatus",
		onChange: (e) => setFilters({ ...filters, status: getValue(e) }),
	});

	return (
		<HStack wrap="wrap" spacing="2">
			<GrLaunch />
			<Text fontWeight="bold">Launch Status: </Text>
			{options.map((value) => {
				const radio = getRadioProps({ value });
				return (
					<RadioCard key={value} {...radio}>
						{value}
					</RadioCard>
				);
			})}
		</HStack>
	);
}
