import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, resetFilters } from "../../redux/launchSlice";
import FilterByLaunchDate from "./FilterByLaunchDate";
import FilterByLaunchStatus from "./FilterByLaunchStatus";
import FilterByUpcoming from "./FilterByUpcoming";

export default function Filters() {
	const dispatch = useDispatch();
	const reset = useSelector((state) => state.launches.resetFilters);

	const [filters, setFilters] = useState({
		upcoming: null,
		status: null,
		launchDate: null,
	});

	useEffect(() => {
		dispatch(filter(filters));
	}, [dispatch, filters]);

	useEffect(() => {
		if (reset) {
			setFilters({
				upcoming: null,
				status: null,
				launchDate: null,
			});
			dispatch(resetFilters(false));
		}
	}, [dispatch, reset]);

	return (
		<Box
			borderColor={useColorModeValue("blue.700", "blue.200")}
			borderWidth={1}
			p={5}
			my={10}
			borderRadius={10}
			w="100%"
		>
			<Text fontSize="2xl" fontWeight="bold">
				Filter
			</Text>
			<FilterByLaunchStatus setFilters={setFilters} filters={filters} />
			<FilterByUpcoming setFilters={setFilters} filters={filters} />
			<FilterByLaunchDate setFilters={setFilters} filters={filters} />
		</Box>
	);
}
