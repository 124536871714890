import { CalendarIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { BiDetail } from "react-icons/bi";
import { CgNametag } from "react-icons/cg";
import { GoRocket } from "react-icons/go";
import { GrLaunch } from "react-icons/gr";
import { MdLocationPin, MdOutlineFlightTakeoff, MdUpcoming } from "react-icons/md";
import LaunchCardText from "./LaunchCardText";

export default function LaunchCard({ launch }) {
	return (
		<Flex
			justify="space-between"
			minH="320px"
			borderWidth="1px"
			direction={{ base: "column", md: "row" }}
			bg="blue.50"
		>
			<Box p="5">
				<LaunchCardText label="Mission Name" data={launch.mission_name} icon={<CgNametag />} />
				<LaunchCardText label="Rocket Name" data={launch.rocket.rocket_name} icon={<GoRocket />} />
				<LaunchCardText
					label="Launch Site"
					data={launch.launch_site.site_name_long}
					icon={<MdLocationPin />}
					noOfLines="1"
				/>
				<LaunchCardText
					label="Launch Date"
					data={new Date(launch.launch_date_local).toDateString().slice(4)}
					icon={<CalendarIcon />}
				/>
				<LaunchCardText
					label="Flight Number"
					data={launch.flight_number}
					icon={<MdOutlineFlightTakeoff />}
				/>
				{launch.launch_success !== null ? (
					<LaunchCardText
						label="Launch"
						data={launch.launch_success ? "Successful" : "Failed"}
						icon={<GrLaunch />}
					/>
				) : (
					""
				)}
				{launch.upcoming ? (
					<LaunchCardText
						label="Upcoming"
						data={launch.upcoming ? "Yes" : "No"}
						icon={<MdUpcoming />}
					/>
				) : (
					""
				)}
				{launch.details !== null ? (
					<HStack wrap="wrap">
						<BiDetail />
						<b>Details: </b>
						<Text noOfLines="4">{launch.details}</Text>
					</HStack>
				) : (
					""
				)}
			</Box>
			{launch.links.flickr_images[0] !== undefined ? (
				<Image src={launch.links.flickr_images[0]} minW="400px" minH="320px" w="400px" h="320px" />
			) : (
				""
			)}
		</Flex>
	);
}
