import { HStack, Switch, Text } from "@chakra-ui/react";
import { MdUpcoming } from "react-icons/md";

export default function FilterByUpcoming({ setFilters, filters }) {
	return (
		<HStack py="6">
			<MdUpcoming />
			<Text fontWeight="bold">Upcoming Launches: </Text>

			<Switch
				size="lg"
				colorScheme="blue"
				isChecked={filters.upcoming !== null}
				onChange={() => {
					setFilters({ ...filters, upcoming: filters.upcoming === null ? true : null });
				}}
			/>
		</HStack>
	);
}
